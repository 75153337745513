import { Link } from 'gatsby';
import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import design from '../images/design@3x.png';
//import Img from "../images/bubble-1.webp"
import elephantImg from '../images/elephant.png';
import bottomLeft from '../images/images/bottomLeft.svg';
import bottomRight from '../images/images/bottomRight.svg';
import inverted1 from '../images/images/inverted1.png';
import inverted2 from '../images/images/inverted2.png';
import topLeft from '../images/images/topLeft.svg';
import topRight from '../images/images/topRight.svg';
import * as headerStyles from '../styles/ourstory.module.css';

const OurStoryPage = () => (
  <Layout>
    <SEO title="Our Story and People" keywords="Instant Life Insurance Coverage, Instant Home Insurance Coverage, best bundle insurance deals"
      canonicalUrl='https://www.getmybubble.com/our-story/' />

    <div className="plainBackground flexRow">
      <div className={headerStyles.ContentHeading}>Our Story</div>
    </div>

    <div className={headerStyles.firstBlock}>
      <div className='container'>
        <div className='row' style={{ margin: '0 auto 0 auto', maxWidth: '1030px' }}>
          <div className='col-lg-4 col-md-12'><img src={design} className={headerStyles.topImg} alt="blockbgimg"/></div>

          <div className='col-lg-8 col-md-12 our-story-section1'>
            <p className={headerStyles.subHeading}>Where it all began</p>

            <p className={headerStyles.para}> Nobel economist Richard Thaler observed that people are predictably irrational, and do not always make rational decisions,
              even if they may be good for them. They need a nudge, at the right time, in the right way.
            <br /><br />
              Guilty as charged. I know that I put off making some of the most important financial decisions of my life -
              forming a trust & will, getting a financial advisor, purchasing life insurance, to name a few - for longer than
              I should have.The only reassurance was that I was not alone - when I realized that nearly 43% of American adults do
              not have any life insurance coverage for their families. And another 18% take the shortest path by signing up with
              whatever their employers offer - often Group Life - which is rarely enough, and typically goes away when you change jobs.</p>
          </div>
        </div>
      </div>
    </div>

    <div className={headerStyles.mask2}>
      <img src={topLeft} className="topLeftCircle" alt="boxbgimg"/>

      <img src={bottomLeft} className="bottomLeftCircle" alt="boxbgimg" />

      <img src={topRight} className="topRightCircle" alt="boxbgimg" />

      <img src={bottomRight} className="bottomRightCircle" alt="boxbgimg" />

      <div className='container'>
        <div className={headerStyles.OurStoryQuote}>
          <img src={inverted1} className={headerStyles.inverted1Img} alt="invertimg" />Make it simple - even fun - to get life insurance,

          <span style={{ color: '#ff7007' }}>&nbsp;when it makes the most sense to us.</span> Such as when we buy a home. Or think about getting married. Or start planning a family.

          <img src={inverted2} className={headerStyles.inverted2Img} alt="invertimg"/>
        </div>
      </div>
    </div>

    <div className={headerStyles.info2}>
      <div className='container'>
        <div className='row' style={{ margin: '0 auto', maxWidth: '970px' }}>
          <div className='col-lg-8 col-md-12 flex-Order2'>

            <p className={headerStyles.para}> The reasons surprised me even more. Many Americans believe life insurance is 3 to 5 times
              more expensive than it actually is. Most are never informed about life insurance. And for those that are, it is often
              through their financial advisors who typically enter their lives when they are older, by when, life policies have already
              got exponentially more expensive. Not to mention, getting a life insurance policy has never been easy - from answering pages
              upon pages of questions, to going through a blood test or health check, waiting days for an accurate quote, and going back and
              forth with your agent to finalize.
            <br /><br />
              We figured it's time to change all that. Make it simple - even fun - to get life insurance, when it makes most sense to us.
              Such as when we buy a home. Or think about getting married. Or start planning a family.</p>
          </div>

          <div className='col-lg-4 col-md-12 flex-Order1' id="img-1"><img src={elephantImg} className={headerStyles.eleImg} alt="bgimg" /></div>

          {/*  */}
        </div>

        <div className='row' style={{ margin: '0 auto', maxWidth: '970px' }}>

          <div className='col-12' >
            <p className={headerStyles.para2}>Bubble's mission is exactly that - to be the simplest, easiest & most convenient way for people to
              protect themselves, their families & properties, for total peace of mind.We have launched Bubble with one of the most natural
              ways to secure your family's future with life insurance - by seamlessly bundling it with home owner insurance when you buy a
              house or refinance your mortgage - often the largest financial transaction, and liability, for most of us.
            <br /><br /><br />
              Bubble makes it easy and quick to get an accurate quote for a bundled home and life insurance policy - tailored to your exact
              needs. And those that qualify can purchase it instantaneously without any hassle. Change your coverage as your life evolves. And
              best of all, set it and leave it - have your life insurance premium paid automatically with your home insurance premium,
              through your mortgage escrow account, so your policy never lapses accidentally.

            <br /><br /><br />
              Now you can sleep at ease in that beautiful home - knowing that both your family and your home are well protected.
            </p>
          </div>

        </div>
      </div>
    </div>


    <div className="plansForYou">
      <div className="orangeBackGround">
        <div>
          Find out which plan is right for you
        </div>

        <div className="customizedPlan">
          Policies are customized according to your needs.
          We have simplified the process to help you find the right policy that fits your budget and loved ones.
        </div>

        <Link to="/" type="button" className="btnWhite">
          Get your quote now
        </Link>
      </div>
    </div>

  </Layout>
);

export default OurStoryPage;
