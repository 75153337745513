// extracted by mini-css-extract-plugin
export var Anil = "ourstory-module--Anil--ElhXV";
export var Avi = "ourstory-module--Avi--bkUVD";
export var ContentHeading = "ourstory-module--ContentHeading--FBTsP";
export var Hrishikesh = "ourstory-module--Hrishikesh--c4ODT";
export var Img = "ourstory-module--Img--GBnXC";
export var MaskTop = "ourstory-module--MaskTop--y9pLC";
export var OurStoryQuote = "ourstory-module--OurStoryQuote--wop3q";
export var Robert = "ourstory-module--Robert--rfbwT";
export var Shridhar = "ourstory-module--Shridhar--Z1ivF";
export var ashok = "ourstory-module--ashok--rbpY8";
export var eleImg = "ourstory-module--eleImg--6I1jQ";
export var firstBlock = "ourstory-module--firstBlock--xI2bx";
export var hr2 = "ourstory-module--hr2--AEq8h";
export var img = "ourstory-module--img--SGGob";
export var info2 = "ourstory-module--info2--A3oVi";
export var inverted1Img = "ourstory-module--inverted1Img--ITsSq";
export var inverted2Img = "ourstory-module--inverted2Img--8rbT8";
export var ld = "ourstory-module--ld--1pM24";
export var mask2 = "ourstory-module--mask2--IuVE5";
export var one = "ourstory-module--one--ISlGi";
export var para = "ourstory-module--para--rcA1Y";
export var para2 = "ourstory-module--para2--J9uun";
export var paraa = "ourstory-module--paraa--sS6yv";
export var subHeading = "ourstory-module--subHeading--nKHas";
export var teamheading = "ourstory-module--teamheading--IEyw4";
export var teaminfo = "ourstory-module--teaminfo--5ieSt";
export var teaminfo2 = "ourstory-module--teaminfo2--ccyN-";
export var teaminfo3 = "ourstory-module--teaminfo3--h-ymL";
export var teaminfo4 = "ourstory-module--teaminfo4--C0Qur";
export var topImg = "ourstory-module--topImg--D+xgp";